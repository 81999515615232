/* CompareJS */
var Mobiles = Mobiles || {};

Mobiles.Filter = function(){

};

Mobiles.Search = function(){

};

Mobiles.Filter.prototype.run = function () {
    var filterSelectors = '';
    var handsetColour   = $('#handset-colour').val();
    var handsetNetwork  = $('#handset-network').val();
    var handsetMinutes  = $('#handset-minutes').val();
    var handsetTexts    = $('#handset-texts').val();
    var handsetData     = $('#handset-data').val();
    var handsetStorage  = $('#handset-storage').val();
    var handsetMonthly  = $('#handset-monthly').val();
    var handsetUpfront  = $('#handset-upfront').val();
    $('.list .row').not('.heading').fadeOut(400);

    if(handsetColour !== '')
    {
        filterSelectors += '[data-colour="'+handsetColour+'"]';
    }
    if(handsetNetwork !== '')
    {
        filterSelectors += '[data-network="'+handsetNetwork+'"]';
    }

    var filterRows = $('.list .row'+filterSelectors).filter(function() {
        /* Minutes */
        if(handsetMinutes !== ''){
            return $(this).attr("data-minutes") >= parseInt(handsetMinutes);
        }
        else {
            return true;
        }
    }).filter(function() {
        /* Texts */
        if(handsetTexts !== ''){
            return $(this).attr("data-texts") >= parseInt(handsetTexts);
        }
        else {
            return true;
        }
    }).filter(function() {
        /* Data */
        if(handsetData !== ''){
            return $(this).attr("data-data") >= parseInt(handsetData);
        }
        else {
            return true;
        }
    }).filter(function() {
        /* Storage */
        if(handsetStorage !== ''){
            return $(this).attr("data-storage") >= parseInt(handsetStorage);
        }
        else {
            return true;
        }
    }).filter(function() {
        /* Storage */
        if(handsetMonthly !== ''){
            return $(this).attr("data-monthly") <= parseInt(handsetMonthly);
        }
        else {
            return true;
        }
    }).filter(function() {
        /* Storage */
        if(handsetUpfront !== ''){
            return $(this).attr("data-upfront") <= parseInt(handsetUpfront);
        }
        else {
            return true;
        }
    }).slice(0,10);

    if(filterRows.length === 0)
    {
        $('.list').hide();
        $('.noMatch').fadeIn();
    }
    else
    {
        $('.noMatch').hide();
        $('.list').show();
        filterRows.fadeIn(400);
    }

};

Mobiles.Filter.prototype.goto = function () {
    $('html, body').animate({
      scrollTop: $(".filter").offset().top
  }, 1000);
};

Mobiles.Search.prototype.run = function (search) {

    if(search !== "")
    {
        $('.masthead .mobile-image:nth-child(3) img').fadeOut();

        $.getJSON("json/mobiles.json", function(data) {
            var items = [],
                itemsCounter = 1,
                limit = 5;

            if($(window).width() < 768)
            {
                limit = 4;
            }

            var results = $(data).filter(function(i,n){
                if(itemsCounter < limit){
                    if(n.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    {
                        items.push('<a href="/mobile-phones/compare/'+n.url+'">'+n.name+'</a>');
                        itemsCounter++;
                    }
                }
            });

            $('.search-results').html(items);
        });
    }
    else {
        $('.search-results').html('');
        $('.masthead .mobile-image:nth-child(3) img, .masthead .mobile-image:nth-child(4) img').fadeIn();
    }
};

(function (Mobiles, $) {

    var filter = new Mobiles.Filter(),
        search = new Mobiles.Search();

    //Load in Top 10 Results
    $('.list .row:lt(10)').fadeIn(400);

    $('.compare .buttons .button').mouseover(function(){
        $('.compare .phones div[data-id="'+$(this).data('id')+'"] a img').css('margin-top', '50px');
    }).mouseout(function()
    {
        $('.compare .phones div[data-id="'+$(this).data('id')+'"] a img').css('margin-top', '100px');
    });

    $('.compare .buttons .button').on('click', function(){
        $('#handset-colour').val($(this).data('colour'));
        filter.run();
        filter.goto();
    });
    $('.compare .phones > div img').on('click', function(){
        $('#handset-colour').val($(this).closest('div').data('colour'));
        filter.run();
        filter.goto();
    });

    $('.filter select').on('change', function(){
        filter.run();
    });

    $('.search-mobiles').on('keyup', function(){
        search.run($(this).val());
    });

    $('.cta').on('click', function(){
        $('#leavingModal').modal('show');

        var id = $(this).data('id');

        $.ajax({
            type: 'post',
            url: '/mobiles/click/'+id
        });
    });

}(Mobiles, jQuery));
